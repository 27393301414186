.PnrSubText {
  font-family: Mulish SemiBold;
  font-size: 16px;
  color: #3f4f5c;
  margin-bottom: 9px;
}

.PnrInput {
  box-sizing: border-box;
  border: 1px solid #1d2937;
  border-radius: 4px;
  height: 60px;
  min-width: 100%;
  padding-left: 13px;
  font-family: Mulish SemiBold;
  color: #3f4f5c;
  font-size: 20px;
  background-color: #eef4f8;
  outline: none;
}

.PnrHelperText {
  font-family: Mulish SemiBold;
  font-size: 12px;
  color: #8996a0;
  margin-top: 2px;
  width: 80%;
}

.PnrContainer {
  height: 500px;
}

.PnrInputCompleted {
  box-sizing: border-box;
  border: 1px solid #1d2937;
  border-radius: 4px;
  height: 60px;
  min-width: 100%;
  padding-left: 13px;
  font-family: Mulish SemiBold;
  color: #3f4f5c;
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .PnrInputCompleted {
    background: url(checkmark_blue.svg) no-repeat scroll 290px 20px;
    background-color: #eef4f8;
    outline: none;
  }
}

@media screen and (min-width: 769px) {
  .PnrInputCompleted {
    background: url(checkmark_blue.svg) no-repeat scroll 317px 20px;
    background-color: #eef4f8;
    outline: none;
  }
}
