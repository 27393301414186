.TransactionsList {
  /* height: 100%; */
  overflow-y: auto;
  height: 560px;
  padding-right: 16px;
}

.TransactionsListItem {
  border-bottom: 1px solid #e5e3e3;
  min-height: 92px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.TransactionsListItemInfo {
  font-family: Mulish Regular;
  font-size: 16px;
  color: #52565a;
}

.TransactionsListItemAmount {
  font-family: Mulish SemiBold;
  font-size: 16px;
  color: #52565a;
  margin-bottom: 6px;
}

.TransactionsListItemDate {
  font-family: Mulish Regular;
  font-size: 14px;
  color: #9c9292;
  margin-top: 6px;
}

.TransactionAccountName {
  font-family: Mulish SemiBold;
  font-size: 18px;
  color: #52565a;
  margin-bottom: 6px;
  margin-top: 6px;
}

.TransactionAccountNumber {
  font-family: Mulish Regular;
  font-size: 12px;
  color: #9c9292;
}

.AccountInformationTab {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 50%;
  font-size: 16px;
  font-family: Mulish Light;
  padding: 16px;
  color: #52565a;
  cursor: pointer;
  border-bottom: 1px solid rgba(209, 205, 205, 0.5);
}

.AccountInformationTabSelected {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 50%;
  font-size: 16px;
  padding: 16px;
  font-family: Mulish SemiBold;
  color: #3f4f5c;
  cursor: pointer;
  border-bottom: 1px solid #3f4f5c;
}
