.Account {
  width: 100%;
  padding: 14px;
  margin: 5px 0;
  color: #4f4f4f;
  border-radius: 8px;
  background-color: white;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #1d2937;
  box-sizing: border-box;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
}

.AccountSelected {
  background: radial-gradient(
      111.96% 2613.22% at 30.24% 50.65%,
      rgba(255, 255, 255, 0.132) 27.96%,
      rgba(91, 114, 136, 0.09) 76.43%
    ),
    #1d2937;
  filter: none;
}

.AccountName {
  font-family: Mulish Regular;
  font-size: 16px;
}

.AccountNumber {
  font-family: Mulish Regular;
  font-size: 14px;
}

.AccountBalance {
  font-family: Mulish Semibold;
  font-size: 16px;
}

.AccountSelectedFont {
  color: white;
}

.AccountNonSelectedFont {
  color: #1d2937;
}

.AccountSummary {
  width: 100%;
  background-color: #f8fafb;
  height: 100px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
}

.AccountSummaryLabel {
  font-family: Mulish SemiBold;
  font-size: 16px;
  margin: 6px 0;
}
.AccountSummaryValue {
  font-family: Mulish Bold;
  font-size: 16px;
  margin: 6px 0;
  text-align: right;
}

.AccountList {
  display: flex;
  flex-direction: column;
  min-height: 330px;
  max-height: 330px;
  overflow-y: auto;
  margin-bottom: 40px;
}

.ReccuringSummary {
  width: 100%;
  background-color: #f8fafb;
  height: 85px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReccuringSummaryContent {
  color: #3f4f5c;
  font-size: 16px;
}

.ReccuringSummaryThin {
  font-family: Mulish Light;
  color: #3f4f5c;
  font-size: 16px;
}

.ReccuringSummaryThick {
  font-family: Mulish Bold;
  color: #3f4f5c;
  font-size: 16px;
}
