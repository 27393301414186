.ChooseAccountHeader {
  font-family: Mulish Bold;
  font-size: 20px;
  color: #013065;
  margin: 24px 0;
}

.InvoiceAccountName {
  font-family: Mulish Bold;
  font-size: 18px;
  color: #013065;
  margin-bottom: 4px;
}

.InvoiceAccountBalance {
  font-family: Mulish Light;
  font-size: 14px;
  color: #3f4f5c;
  margin-top: 4px;
}

.InvoiceAccountsAccount {
  margin: 16px 0;
}

.InvoiceAccountsAction {
  width: 100%;
  border: none;
  font-family: Mulish SemiBold;
  font-size: 24px;
  background-color: #05a6ee;
  color: white;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 16px;
}
