.ApiTerminal {
  box-sizing: border-box;
  box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
    0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
    0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075);
  width: 600px;
  height: 320px;
  background-color: #1d2937;
  border: 2px solid #1d2937;
  border-radius: 2px;
  padding: 24px 0 24px 30px;
  margin-left: 20px;
  position: absolute;
}

@media screen and (max-width: 1100px) {
  .ApiTerminal {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .AppWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .App {
    background-color: white;
    box-shadow: rgb(0 0 0 / 22%) 0px 2px 18px;
    width: 400px;
    height: 700px;
    border-radius: 8px;
  }

  .AppContentWrapper {
    position: static;
    height: 100%;
    width: 100%;
  }

  .AppContent {
    padding: 24px;
    height: 100%;
  }
}

.ApiTerminalContent {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 262px;
  padding-right: 30px;
}

span {
  font-family: Mulish SemiBold;
  font-size: 14px;
}
.Color-White {
  color: #ffffff;
}
.Color-Blue {
  color: #3f4f5c;
}

.Color-Yellow {
  color: #f8b844;
}

.Color-Grey {
  color: #afbfce;
}

.ApiTerminalPauseButton {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.loading-dots:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 1500ms infinite;
  animation: ellipsis steps(4, end) 1500ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1em;
  }
}
