.BankImage {
  height: 40px;
  width: 40px;
  margin: 0 10px 0 0;
}
.BankName {
  font-family: Mulish Regular;
  font-size: 16px;
}

.ChooseBankList {
  max-height: 400px;
  overflow-y: auto;
}

.TermsAndConditions {
  padding-top: 10px;
  padding-right: 10px;
  font-size: 10px;
  color: grey;
}

.TermsAndConditionsText {
  font-family: Mulish Regular;
  font-size: 10px;
  color: grey;
}

.TermsAndConditionsList {
  font-family: Mulish Regular;

  font-size: 10px;
  color: grey;
}

.TermsAndConditionsListItem {
  font-family: Mulish Regular;

  font-size: 10px;
  color: grey;
}

.TermsAndConditionsShowMore {
  font-family: Mulish SemiBold;
  font-size: 10px;
  color: black;
  text-decoration: underline;
  cursor: pointer;
  margin: 6px 0 8px 0;
}
