.Field {
  margin-top: 16px;
}

.FieldName {
  font-family: Mulish Light;
  font-size: 14px;
  color: #666666;
  margin-bottom: 4px;
}
.FieldValue {
  font-family: Mulish SemiBold;
  font-size: 24px;
  color: #666666;
}
