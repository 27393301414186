.TheLoader {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .TheLoader {
    /* position: absolute; */
    position: absolute;
    top: 40vh;
    /* left: 50vh; */
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 769px) {
  .TheLoader {
    position: relative;
  }
}

.TheLoaderText {
  font-family: Mulish Semibold;
  font-size: 16px;
  color: #3f4f5c;
  text-align: center;
  line-height: 24px;
  padding: 0 20px;
}
