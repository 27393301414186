.AppContentWrapper {
  position: static;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .App {
    width: 100%;
    height: 90vh;
    background-color: white;
    padding-bottom: 20px;
  }

  .AppContentWrapper {
    height: 90vh;
  }
}

@media screen and (min-width: 769px) {
  .AppWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .App {
    background-color: white;
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 22%) 0px 2px 18px;
    height: 700px;
    width: 400px;
    position: absolute;
    z-index: 999;
  }
}
