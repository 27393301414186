.BankImage {
  height: 40px;
  width: 40px;
  margin: 0 10px 0 0;
}

.InvoiceLine {
  border-bottom: 1px solid rgba(209, 205, 205, 0.5);
  margin: 16px 0;
}

.InvoiceBankField {
  font-family: Mulish Light;
  font-size: 14px;
  color: #666666;
  margin-bottom: 4px;
}

.InvoiceBankValue {
  font-family: Mulish SemiBold;
  font-size: 24px;
  color: #666666;
}

.InvoicePayButton {
  width: 100%;
  border: none;
  font-family: Mulish SemiBold;
  font-size: 24px;
  background-color: #05a6ee;
  color: white;
  padding: 12px;
  border-radius: 8px;
}

.PoweredBy {
  font-family: Mulish Light;
  color: #828282;
  font-size: 10px;
  min-width: 56px;
}
.PoweredByContainer {
  margin-top: 24px;
}

.react-datepicker-ignore-onclickoutside {
  padding: 20px;
}

input,
textarea {
  padding: 12px;
  border: 1px solid grey;
  border-radius: 4px;
  font-family: Mulish Regular !important;
  font-size: 16px !important;
  max-width: 120px !important;
}

.react-datepicker-ignore-onclickoutside {
  padding: 12px;
}
