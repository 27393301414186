.InvoiceQRInfo {
  line-height: 1.5;
}

.InvoiceQRMobileLink {
  font-size: 16px;
  font-family: Mulish Regular;
  margin: 24px 0;
}

@media screen and (max-width: 768px) {
  .InvoiceQRDesktop {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .InvoiceQRMobile {
    display: none;
  }
}
