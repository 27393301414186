@media screen and (max-width: 500px) {
  .UseCase {
    display: none;
  }

  .UseCaseSmall {
    display: flex;
    align-items: center;
    padding: 30px;
    background-color: #1d2937;
    margin-bottom: 16px;
    flex: 1;
  }

  .UseCaseHeader {
    font-family: Mulish SemiBold;
    font-size: 20px;
    color: #ffffff;
    flex-grow: 1;
  }
  .UseCaseIconWrapper {
    display: none;
  }
  .UseCaseIcon {
    display: none;
  }

  .UseCaseIconWrapperSmall {
    width: 30px;
    height: 30px;
    margin-right: 24px;
  }
  .UseCaseIconSmall {
    max-width: auto;
    max-height: 100%;
  }

  .UseCaseText {
    display: none;
  }

  .UseCaseDocsLink {
    display: none;
  }

  .UseCaseAction {
    display: none;
  }
}

@media screen and (min-width: 501px) {
  .UseCaseSmall {
    display: none;
  }

  .UseCase {
    width: 271px;
    height: 340px;
    left: 106px;
    top: 437px;

    background: #ffffff;
    box-shadow: 8px 16px 16px rgba(63, 79, 92, 0.15),
      4px 8px 8px rgba(63, 79, 92, 0.15), 2px 4px 4px rgba(63, 79, 92, 0.15),
      1px 2px 2px rgba(63, 79, 92, 0.15), 0.5px 1px 1px rgba(63, 79, 92, 0.15);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    margin: 20px;
  }

  .UseCaseHeader {
    font-family: Mulish Bold;
    font-size: 20px;
    color: #2d1e1e;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .UseCaseText {
    font-family: Mulish Regular;
    font-size: 16px;
    color: #2d1e1e;
    line-height: 24px;
    margin-bottom: 24px;
    height: 120px;
  }

  .UseCaseIcon {
    max-width: auto;
    max-height: 100%;
  }

  .UseCaseAction {
    font-family: Mulish SemiBold;
    font-size: 14px;
    background-color: #1C1C2A;
    color: #ffffff;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .UseCaseDocsLink {
    font-family: Mulish SemiBold;
    font-size: 14px;
    color: #1C1C2A;
    margin-right: 16px;
    z-index: 15;
  }

  .UseCaseIconWrapper {
    width: 50px;
    height: 50px;
    margin-bottom: 16px;
  }

  .UseCaseIconWrapperSmall {
    display: none;
  }
  .UseCaseIconSmall {
    display: none;
  }
}
