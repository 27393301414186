.Button {
  font-size: 18px;
  font-family: Mulish Bold;
  padding: 12px 16px;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 8px;
  background-color: #1d2937;
  border: 0;
  color: white;
  width: 100%;
  height: 52px;
  cursor: pointer;
}

.Button:disabled {
  background-color: #b2b5b8;
}
