.PageHeader {
  position: static;
  font-family: Mulish Bold;
  font-size: 20px;
  color: #202934;
  line-height: 1.5;
}

.PageHeaderWrapper {
  padding: 12px 0 24px 0;
}
