.ErrorMessageHeader {
  font-family: Mulish Bold;
  font-size: 20px;
  color: #3f4f5c;
  margin-bottom: 16px;
  margin-top: 200px;
}

.ErrorMessageLink {
  font-family: Mulish SemiBold;
  font-size: 16px;
  color: #1d2937;
  text-decoration: underline;
  margin-top: 16px;
  cursor: pointer;
}

.extendedbankidpara {
  font-family: Mulish Regular;
  font-size: 16px;
}
