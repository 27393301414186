body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f4f4;
  padding: 0;
  box-sizing: border-box;
  color: #4f4f4f;
}

@media screen and (max-width: 768px) {
  body {
    background-color: white;
    height: 90vh;
  }
  #root {
    height: 90vh;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"),
    url(../src/assets/fonts/OpenSans-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Open Sans SemiBold";
  src: local("Open Sans SemiBold"),
    url(../src/assets/fonts/OpenSans-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans Bold";
  src: local("Open Sans Bold"),
    url(../src/assets/fonts/OpenSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Mulish Bold";
  src: local("Mulish Bold"),
    url(../src/assets/fonts/Mulish-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Mulish Italic";
  src: local("Mulish Italic"),
    url(../src/assets/fonts/Mulish-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "Mulish Light";
  src: local("Mulish Light"),
    url(../src/assets/fonts/Mulish-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Mulish Medium";
  src: local("Mulish Medium"),
    url(../src/assets/fonts/Mulish-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Mulish Regular";
  src: local("Mulish Regular"),
    url(../src/assets/fonts/Mulish-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Mulish SemiBold";
  src: local("Mulish SemiBold"),
    url(../src/assets/fonts/Mulish-SemiBold.ttf) format("truetype");
}

h1 {
  font-size: 24px;
  font-family: Mulish Bold;
  color: #3f4f5c;
  margin-bottom: 40px;
}

h2 {
  font-size: 20px;
  margin: 10px 0 10px 0;
}

p {
  font-size: 16px;
  margin-bottom: 22px;
  line-height: 1.8rem;
}

span {
  font-family: Mulish SemiBold;
  font-size: 16px;
  color: #1d2937;
}

select {
  color: #828282;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  margin: 0 12px;
  outline: none;
}

option {
  color: #828282;
  padding: 8px 16px;
  font-size: 16px;
}

.ope-dark-blue {
  color: #3f4f5c;
}
