.ShowApiCalls {
  bottom: -390px;
  right: -180px;
  position: absolute;
  display: flex;
  cursor: pointer;
}

.ShowApiCallsCheckbox {
  margin-right: 4px;
  margin-top: 2px;
  cursor: pointer;
  filter: #1d2937;
}
.ShowApiCallsLabel {
  font-family: Mulish Regular;
  font-size: 14px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .ShowApiCalls {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .ShowApiCalls {
    display: none;
  }
}
