.Header {
  border-bottom: 1px solid rgba(209, 205, 205, 0.5);
  height: 60px;
  min-height: 60px;
  padding: 10px 10px;
}

.HeaderInvoice {
  border-bottom: 1px solid rgba(209, 205, 205, 0.5);
  height: 60px;
  min-height: 60px;
  padding: 10px 10px 10px 24px;
}

.HeaderHeader {
  font-family: Mulish Regular;
  font-size: 24px;
}

.HeaderBankImage {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.HeaderBankName {
  font-family: Mulish Bold;
  font-size: 14px;
  color: #3f4f5c;
}

.HeaderFaktura {
  font-family: Mulish Regular;
  font-size: 18px;
  color: #4f4f4f;
}

.HeaderCompanyName {
  font-family: Mulish Regular;
  font-size: 13px;
  color: #013065;
  margin-left: 10px;
}
