.InvoiceSuccessfulName {
  font-family: Mulish Regular;
  font-size: 16px;
  color: #666666;
}

.InvoiceSuccessfulValue {
  font-family: Mulish Bold;
  font-size: 16px;
  color: #666666;
}

.InvoiceSuccessfulHeader {
  font-family: Mulish Bold;
  font-size: 24px;
  color: #013065;
  margin: 48px 0;
}

.InvoiceSuccessfulButton {
  width: 100%;
  border: none;
  font-family: Mulish SemiBold;
  font-size: 24px;
  background-color: #05a6ee;
  color: white;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 24px;
}

.namevaluecontainer {
  margin-bottom: 28px;
}

.PageContainerInvoiceSuccessful {
  justify-content: space-between;
}
