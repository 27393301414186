.InvoiceStatusEvent {
  height: 50px;
  display: flex;
  flex-direction: column;
}

.InvoiceStatusEventSpace {
  border-left: 1px solid #dedada;
  flex: 1;
  margin-left: 6px;
}

.Hidden {
  visibility: hidden;
}

.BlueBall {
  width: 13px;
  height: 13px;
  margin-right: 20px;
}

.InvoiceStatusEventText {
  font-family: Mulish SemiBold;
  color: #1d2937;
  font-size: 16px;
}

.InvoiceStatusEventTextSuccess {
  font-family: Mulish SemiBold;
  color: #f8b844;
  font-size: 16px;
}

.InvoiceStatusCheckmarkYellow {
  width: 140px;
  height: 111px;
  margin-top: 50px;
}
