.PageContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

@media screen and (max-width: 768px) {
  .PageContainer {
    width: 100%;
    height: calc(90vh - 100px);
  }
}

@media screen and (min-width: 769px) {
  .PageContainer {
    height: 640px;
    width: 400px;
  }
}
