.PaymentTypeSelect {
  margin: 0;
  padding: 10px;
  /* padding-right: 20px; */
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-family: Mulish Regular;
  color: #000000;
}

.PaymentTypeOption {
  padding: 10px;
}

.InvoiceMessageInput {
  min-width: 100%;
  resize: none;
}

.CreateInvoiceButton {
  width: 100%;
  border: none;
  font-family: Mulish SemiBold;
  font-size: 24px;
  background-color: #202934;
  color: white;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 24px;
}
