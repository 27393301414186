.ChooseSparbankRoot {
  width: 400px;
  height: 600px;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-top: 12px;
}

.ChooseSparbankList {
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.SparbankListItem {
  width: 350px;
  font-family: Mulish SemiBold;
  font-size: 16px;
  color: white;
  padding: 8px;
  margin-top: 8px;
  border-radius: 2px;
  cursor: pointer;
}

.SparbankListItemLight {
  background-color: #7a91a7;
}

.SparbankListItemDark {
  background-color: #1d2937;
}
