.InfoBox {
  display: flex;
  flex-direction: row;
  padding: 12px;
  background-color: #eaf2ff;
}

.InfoBoxIcon {
  width: 26px;
  height: 26px;
  margin-right: 4px;
}

.InfoBoxText {
  font-family: Mulish Regular;
  font-size: 18px;
  color: #184a9a;
  line-height: 1.5;
}
