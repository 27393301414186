.CheckboxContainer {
  display: flex;
  cursor: pointer;
}

.Checkbox {
  margin-right: 4px;
  margin-top: 2px;
  cursor: pointer;
  filter: #1d2937;
}
.Label {
  font-family: Mulish Regular;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 26px;
}

.extendedbankidpara {
  font-family: Mulish Regular;
  font-size: 16px;
}
