.Choice {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  color: white;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
  background-color: #1d2937;
  border-radius: 5px;
  cursor: pointer;
}

.Choice:hover {
  opacity: 0.8;
}

.Choice span {
  font-size: 1.2em;
  margin-left: 10px;
  color: white;
}

.AccountTypeHeader {
  margin-bottom: 30px;
}
