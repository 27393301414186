@media screen and (max-width: 500px) {
  .LandingPageHeader {
    font-family: Mulish Bold;
    font-size: 36px;
    line-height: 1.1;
    color: #202934;
    margin: 24px 0;
  }

  .LandingPageText {
    font-family: Mulish Regular;
    font-size: 20px;
    line-height: 1.5;
    color: #202934;
    margin-bottom: 12px;
  }

  .UseCaseList {
    display: flex;
    flex-direction: column;
    max-width: 460px;
  }

  .LandingPage {
    display: flex;
    flex-direction: column;
    padding: 36px 24px;
    text-align: start;
  }

  .LandingPageSubtextWrapper {
    margin-bottom: 48px;
  }

  .LandingPageOpeLogo {
    display: none;
  }

  .LandingPageOpeLogoSmall {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 53px;
    height: 30px;
  }
}

@media screen and (min-width: 501px) {
  .LandingPageHeader {
    font-family: Mulish SemiBold;
    font-size: 48px;
    line-height: 1.2;
    color: white;
    margin: 48px;
    max-width: 670px;
    text-align: center;
  }

  .LandingPageText {
    font-family: Mulish SemiBold;
    font-size: 20px;
    line-height: 1.75;
    color: white;
    /* margin: 48px; */
    max-width: 560px;
    text-align: center;
  }
  .LandingPage {
    width: 100vw;
    min-height: 100vh;
    background: linear-gradient(
      to bottom,
      #1C1C2A 650px,
      #f5f4f4 calc(100vh - 750px)
    );
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 26px;
  }

  .LandingPageBig {
    width: 125vw;
    min-height: 125vh;
  }

  .LandingPageSubtextWrapper {
    margin-bottom: 96px;
  }

  .LandingPageOpeLogo {
    width: 94px;
    height: 54px;
  }

  .LandingPageOpeLogoSmall {
    display: none;
  }
}

.UseCaseList {
  flex-wrap: wrap;
}

.signup-link {
  color: #509EF8;
}

.signup-link:hover {
  color: #509EF8;
  text-decoration: underline;
}
